.footer {
  display: none;
}

@media (min-width: 900px) {
  .footer {
    display: flex;
  }
 }

.gradient-box-about {
  border: double 1px transparent;
  border-radius: 8px;
  background-image: linear-gradient(#1F2937, #1F2937),         /*here must be*/
                    linear-gradient(to bottom, #7C2D12, #111827);    /*both gradients*/
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.box-team {
  border: double 2px #85BF4F;
  border-radius: 8px;
  margin: 2em;
}